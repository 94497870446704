import { render, staticRenderFns } from "./ChecklistItemDetailModal.vue?vue&type=template&id=05922898&scoped=true&"
import script from "./ChecklistItemDetailModal.vue?vue&type=script&lang=js&"
export * from "./ChecklistItemDetailModal.vue?vue&type=script&lang=js&"
import style0 from "./ChecklistItemDetailModal.vue?vue&type=style&index=0&id=05922898&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05922898",
  null
  
)

export default component.exports