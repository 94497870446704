<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <route-back-button/>
    </div>
    <!-- Top Navigation Finished-->

    <div class="modals">
      <modal :show.sync="modals.printChecklistModal" size="lg" class="printChecklistModal">
        <div class="modal-header pb-0 pl-0 pr-0 pt-3 mt-0">
          <h2 class="text-left">Print</h2>
          <button type="button" class="close pt-4" data-dismiss="modal" aria-label="Close" @click="modals.printChecklistModal=false">
            <span aria-hidden="true" class="text-black">&times;</span>
          </button>
        </div>

        <template slot="default">
          <div class="mt-4">
            <base-input label="Insurance Coordinator" name="insuranceCoordinator">
              <el-select
                class="w-100"
                placeholder="Select Insurance Coordinator"
                v-model="printChecklist.insuranceCoordinator"
                @change="onSelectInsuranceCoordinator">
                <el-option
                  v-for="option in response.insuranceCoordinators"
                  class="select-danger"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="row">
            <div class="col printModalInfo" id="printModalInfo">
              <p v-html="response.insuranceCoordinator.content"></p>
              <div class="table-responsive" id="showTable" style="display: none">
                <table class="tblPrintChecklist">
                  <thead style="background-color: #939597; height: 59px">
                  <tr style="height: 59px; border-bottom: 0.5px solid #E6E6E6">
                    <th class="tbl-ic-th-custom-styling" style="padding-left:13px; width: 25%; text-align: left; font: normal normal 600 15px/20px Open Sans;">Checklist Items</th>
                    <th class="tbl-ic-th-custom-styling" style="width: 25%; text-align: left; font: normal normal 600 15px/20px Open Sans;">Complete Before</th>
                    <th class="tbl-ic-th-custom-styling" style="width: 25%; text-align: left; font: normal normal 600 15px/20px Open Sans;">Completed Date</th>
                    <th class="tbl-ic-th-custom-styling" style="width: 25%; text-align: left; font: normal normal 600 15px/20px Open Sans;">Completed By</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="checklistItem in response.checklistDataList">
                    <td style="padding-left:13px; text-align: left; font: normal normal 600 12px/17px Open Sans; color: #000000; letter-spacing: 0.48px; border-bottom: 0.5px solid #E6E6E6" class="" >{{checklistItem.todo_name}}</td>
                    <td style="text-align: left; font: normal normal 600 12px/17px Open Sans; color: #000000; letter-spacing: 0.48px; border-bottom: 0.5px solid #E6E6E6" class="">{{checklistItem.todo_due_date}}</td>
                    <td style="text-align: left; font: normal normal 600 12px/17px Open Sans; color: #000000; letter-spacing: 0.48px; border-bottom: 0.5px solid #E6E6E6" class="">{{checklistItem.is_completed_date}}</td>
                    <td style="text-align: left; font: normal normal 600 12px/17px Open Sans; color: #000000; letter-spacing: 0.48px; border-bottom: 0.5px solid #E6E6E6" class="cls-checked-by" :class="checklistItem.checked_by =='Program'||checklistItem.checked_by =='Patient' ? 'clr-blue' : 'clr-red'">{{checklistItem.checked_by =='' ? 'incomplete' : checklistItem.checked_by}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mt-4 px-1">
            <p class="note-label"><b>Note:</b> If you want to add the contact information of an insurance coordinator or other
              team member to the top of your patient's checklist, choose the template that you'd like from the dropdown.
             Otherwise, click 'Print'.</p>
          </div>
        </template>
        <template slot="footer">
          <div class="m-0 p-0">
            <base-button class="btn-purple pl-3 pr-3 br-6" type="secondary" @click="modals.printChecklistModal=false">Cancel</base-button>
            <base-button class="btn-primary-action pl-4 pr-4 br-6" type="success" @click="onConfirmPrintChecklistModal">
              Print
            </base-button>
          </div>
        </template>
      </modal>
      <modal :show.sync="modals.changeStatusModal" v-if="checklistStatus==0">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold" id="changeStatusModal">Reason for Lost</h4>
        </template>
        <template slot="default">
          <div class="row ">
            <div class="col-md-6 d-flex">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r1" v-model="selectedReason" value="deactivateOptions.r1.value" class="m-0 base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">Lost contact with patient</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r2" v-model="selectedReason"  value="deactivateOptions.r2.value" class="m-0 base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">Denied by insurance</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center justify-content-center">
                <base-radio name="r3" v-model="selectedReason"  value="deactivateOptions.r3.value" class="m-0 base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">No longer want to have weight loss surgery</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r4" v-model="selectedReason"  value="deactivateOptions.r4.value" class="m-0  base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">Unhappy with program</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r5" v-model="selectedReason"  value="deactivateOptions.r5.value" class="m-0  base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">Does not meet criteria</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r6" v-model="selectedReason"  value="deactivateOptions.r6.value" class="m-0  base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">Out of network insurance</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r7" v-model="selectedReason" value="deactivateOptions.r7.value" class="m-0  base-radio-cls d-flex align-items-center" selected>
                  <span class="base-radio-text">{{deactivateOptions.r7.label}}</span>
                </base-radio>
              </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <div class="">
            <base-button type="secondary" class="btn-gray" @click="modals.changeStatusModal=false">Cancel</base-button>
            <base-button class="remove-btn pl-5 pr-5 br-4" type="success" @click="onSaveChangeStatus">
              Deactivate
            </base-button>
          </div>
        </template>
      </modal>
      <modal :show.sync="modals.checklistAlertModal" class="checklist-alert-modal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold mb-0 pt-0" id="checklistAlertModal">Are you sure?</h4>
        </template>
        <template slot="default">
          <p class="m-0 p-0 alert-message">Do you want to alert this patient that their checklist has been updated?</p>
        </template>
        <template slot="footer">
          <div class="m-0 p-0">
            <base-button class="btn-purple pl-3 pr-3 br-6" type="secondary" @click="modals.checklistAlertModal=false">Cancel</base-button>
            <base-button class="btn-primary-action pl-4 pr-4 br-6" type="success" @click="onConfirmChecklistAlertModal">
              Yes
            </base-button>
          </div>
        </template>
      </modal>
      <modal :show.sync="modals.changeStageConfirmationModal" class="change-stage-confirmation-modal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold mb-0 pt-0 pb-0" id="changeStageConfirmationModal">Are you sure?</h4>
        </template>
        <template slot="default">
          <p class="m-0 p-0 confirmation-message">You want to move this patient to this stage?</p>
        </template>
        <template slot="footer">
          <div class="m-0 p-0 footer-button">
            <base-button class="btn-gray pl-4 pr-4" @click="modals.changeStageConfirmationModal=false">No</base-button>
            <base-button class="btn-primary-action pl-4 pr-4" @click="onConfirmChangeStageModal">
              Yes
            </base-button>
          </div>
        </template>
      </modal>
    </div>
    <div class="m-0 pl-3 pt-4 pb-0 pr-2">
      <h1 class="ml-3 mb-0 d-inline-block mt-2">Checklists
        <small>
          <router-link :to="{name: 'PatientDetail', params: {id: request.patientId}}" class="txt-clr">
            <span class="color-blue-gray">({{this.patientName}})</span>
          </router-link>
        </small>
        <el-tooltip
          v-if="snoozed"
          class="item" effect="dark"
          :content="'This patient has been snoozed from the Stalled Patient Report.'"
          :visible-arrow="false"
        >
          <img v-if="snoozed" class="mt-1 mr-1" src="/img/svg/snooze.svg" alt=""/>
        </el-tooltip>
        <el-tooltip
          v-if="stalledDismiss"
          class="item" effect="dark"
          :content="'This patient has been dismissed from the Stalled Patient Report.'"
          placement="bottom-start"
          :visible-arrow="false"
        >
          <img v-if="stalledDismiss" class="mt-1" src="/img/icons/buttons/cross-bold.svg" alt=""/>
        </el-tooltip>
      </h1>
      <router-link :to="{name: 'ChecklistItems', params: {id: request.patientId, tempStage: request.type}}">
        <base-button size="lg" class="float-right mr-4 mt-3 d-inline-block btn-secondary-action button-font button-hover-effect title-btn-padding" type="secondary">
          Checklist Items
        </base-button>
      </router-link>
    </div>
    <div class="mt-0 ml-0 mb-0 mr-0 pl-3 pt-0 pb-0 pr-2">
        <div class="dt-bg-clr">
         <span class="d-inline-block"> Surgery Date</span>
          <custom-date-picker class="d-inline-block w-50 heading-datepicker"
                              :inputData.sync="surgeryDate"
                              :today="true"
                              :show-image-icon="true"
                              @change="onChangeSurgeryDate">
          </custom-date-picker>
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center mt-0 mb-0">
      <ul class="breadcrumb wizard">
        <li v-for="(pbStage,key) in pbStages" :class="pbStage.order_key<=pbCurrentStage?'active':''" :style="{ maxWidth: pbStageMaxLength + '%' }">
          <a href="javascript:void(0);" class="pb-internal-a" @click="toggleChangeStageModal(pbStage)">
            {{pbStage.title}}
            <span class="pb-internal-span">{{pbStage.days}} Days</span>
          </a>
        </li>
      </ul>
    </div>
    <base-header class="pb-6" style="height: 150px">
    </base-header>
    <div class="container-fluid mt--8 mb-5">
      <div>

        <div class="tabls-container custom-checklist-table mt-3" v-loading="loaders.checklists">
          <el-tabs
            class="tab_main"
            type="border-card"
            v-model="request.tab"
            @tab-click="tabClick"
          >
            <div class="row">
              <div class="col-md-3">
                <h3 class="text-uppercase mt-2 mb-4 font-weight-700">{{this.checklistTabs[request.tab]}}</h3>
              </div>
              <div class="col-md-9 text-right">

                <base-button type="secondary" class="p-0 mr-3 font-weight-normal" @click="togglePrintChecklistModal">
                  <img src="/img/icons/buttons/print.svg" alt="Print">
                </base-button>
                <base-button type="secondary" class="pl-0 pt-0 pb-0 pr-2 mr-3 mb-0 font-weight-normal" @click="toggleChangeStatusModal" v-if="checklistStatus==0">
                  <img src="/img/icons/buttons/deactivate.svg" alt="Deactivate">
                  Mark as Lost
                </base-button>
                <base-button type="secondary" class="pl-0 pt-0 pb-0 pr-2 mr-3 font-weight-normal" @click="activateChecklist" v-if="checklistStatus==1">
                  <img src="/img/icons/buttons/activate.svg" alt="Activate">
                  Mark as Return
                </base-button>
                <base-button type="secondary" class="btnAlert font-weight-normal" @click="toggleChecklistAlertModal">
                  Alert Patient
                </base-button>
                <div>
                </div>
                <div>
                </div>
              </div>
            </div>
            <el-tab-pane v-for="(checklistTab , index) in checklistTabs"  :label="checklistTab">
                <checklist-form
                  :ref="'checklistForm_' + index "
                  :checklist-items="response.checklistItemsList"
                  :checklist-table-data="checklistTableData"
                  :temp-stage="request.type"
                  :patient-id="request.patientId"
                  :surgery-date="surgeryDate"
                  :consult-date="startDate"
                  :auto-calc-due-date="autoCalculateDueDate"
                  :loader="loaders.checklists"
                  @updateStartDate = "updateStartDate"
                  @updateChecklistItem = "updateChecklistItem"
                  @createChecklistItem="createChecklistItem"
                  @onChangeAutoCalcDueDate="onChangeAutoCalcDueDate"
                  @dataLoaded="dataLoaded"
                  @resetLoadedTabsCounts="resetLoadedTabsCounts"
                  @getChecklistTableData="getChecklistTableData"
                  @startLoader="startLoader"
                />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="tabls-container mt-3">
          <notes
            :stage-type="request.type"
            screen="Checklists"
          />
        </div>
      </div>
    </div>

    <div v-if="checklistStatus==1" class="deactivation-banner d-flex align-items-center justify-content-center" v-bind:style="{ width: sidebarWidth + 'px' }">
      <img class="mr-2" src="/img/svg/block.svg" alt="Deactivated"><p class="m-0 deactivation-banner-p"><b>Checklist Deactivated: </b>No Longer wants to have a weight loss surgery</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Notes from "@/views/Components/Notes/Notes";
import BackButton from "@/components/Router/BackButton";
import pickerOptions from "@/constants/pickerOptions";
import ChecklistForm from "@/views/Pages/Checklists/ChecklistForm";
import { Loading } from 'element-ui';
import CustomDatePicker from "../../Components/CustomDatePicker";

export default {
  name: "Checklist.vue",
  components: {
    ChecklistForm,
    Notes,
    RouteBackButton: BackButton,
    CustomDatePicker,
  },
  props:{

  },

  data(){
    return {
      checklistTableData: {},
      checklistTabs: {
        0 : 'Pre-Op',
        1 : 'Post-Op',
        2 : 'Weight Management',
      },
      modals: {
        printChecklistModal:false,
        changeStatusModal:false,
        checklistAlertModal:false,
        changeStageConfirmationModal:false,
      },
      loaders : {
        alerts : false,
        checklists : false,
      },
      deactivateOptions: {
          r1: {value: 'Lost contact with patient', label: 'Lost contact with patient'},
          r2: {value: 'Denied by insurance', label: 'Denied by insurance'},
          r3: {value: 'No longer want to have weight loss surgery', label: 'No longer want to have weight loss surgery'},
          r4: {value: 'Unhappy with program', label: 'Unhappy with program'},
          r5: {value: 'Does not meet criteria', label: 'Does not meet criteria'},
          r6: {value: 'Out of network insurance', label: 'Out of network insurance'},
          r7: {value: 'Other', label: 'Other'},
      },
      selectedReason:'r7',
      request : {
        patientId:this.$route.params.id,
        tab : 0,
        page : 1,
        type : 1,
        perPage:20,
        notification:'',
      },
      response : {
        checklistItemsList: [],
        checklistTemplatesList: [],
        checklistDataList : [],
        pagination: {},
        insuranceCoordinators:[],
        insuranceCoordinator: {},
      },
      checklistStatus:0,
      surgeryDate:'',
      patientName:'',
      stalledDismiss: 0,
      snoozed: 0,
      pickerOptions: {
        surgeryDateOptions: pickerOptions.surgeryDateOptions,
      },
      dtpicker:'dtpicker',
      sidebarWidth : window.innerWidth - 250,
      printChecklist : {
        insuranceCoordinator : '',
      },
      pbCurrentStage:1,
      pbStages : [],
      pbStageMaxLength : 1,
      currentStage: {},
      autoCalculateDueDate: false,
      startDate:'',

      tabsCount:3,
      loadedTabsCount:0,
    }
  },

  mounted() {
    this.loaders.checklists = true;
    this.getSurgeryDate();
    this.getChecklistItems();
    this.getInsuranceCoordinators();
    this.$root.$on('isSideBarMinimize',(data)=>{
      if(data == true)
        this.sidebarWidth = window.innerWidth - 250;
      else{
        this.sidebarWidth = window.innerWidth - 64;
      }
    });

    //this.getStagesWithDays();
  },

  methods : {

    tabClick(){
      this.request.type= parseInt(this.request.tab) + 1;
      this.updateType();
      this.getChecklistTableData();
      this.getChecklistItems();
    },

    startLoader() {
      this.loaders.checklists = true
    },
    getChecklistTableData() {
      let vm = this;
      vm.loaders.checklists = true
      axios.get(vm.$store.getters.getBaseUrl + `/api/checklists?patientId=${vm.request.patientId}&temp_stage=${vm.request.type}`)
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.checklistTableData = response.data.data.data;
          vm.loaders.checklists = false
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Patients Checklists Retrieval ',
          message: message
        });
      }).finally(() => {
        // vm.loaders.items = false;
      });
    },

    handleSurgeryDate(e) {
      if (moment(e).format('MM/DD/YYYY') == 'Invalid date') {
        this.surgeryDate = '';
      }
    },

    onContextSurgeryDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.surgeryDate = moment(ctx.selectedFormatted).format('MM-DD-YYYY')
        this.updateSurgeryDate();
      }
    },

    onChangeSurgeryDate() {
      // this.handleSurgeryDate(this.surgeryDate)
      this.updateSurgeryDate();
    },

    updateType() {
      this.$root.$emit('updateType',this.request.type);
    },

    getChecklistItems() {
      let vm = this;
      // vm.loaders.items = true;
      axios.get(vm.$store.getters.getBaseUrl + `/api/checklist/items?tempStage=${vm.request.type}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.checklistItemsList = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Items',
          message: message
        });
      }).finally(() => {
        // vm.loaders.items = false;
      });
    },

    loaderTimeOut(){
      setTimeout(() => { this.loaders.checklists = false;}, 2000);
    },

    //this api now will also fetch the third checklist name
    getSurgeryDate(){
      let vm = this;
      axios.get(
        `${vm.$store.getters.getBaseUrl}/api/surgery-date?patientId=${vm.request.patientId}`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.checklistTabs[2] = response.data.data.third_checklist_name;
          var current_stage = response.data.data.current_stage
          if (current_stage == '150'){
            vm.request.tab = '2';
            vm.request.type= 3;
          }
          else if(response.data.data.surgery_date && response.data.data.surgery_date!='0000-00-00'){
            vm.surgeryDate = moment(response.data.data.surgery_date,'YYYY-MM-DD').format('MM/DD/YYYY');
            if (moment(vm.surgeryDate) <= moment.now())
            {
              vm.request.tab = '1';
              vm.request.type= 2;
            }
            else {
              vm.request.tab = '0';
              vm.request.type= 1;
            }
          }
          else {
            vm.request.tab = '0';
            vm.request.type= 1;
          }

          vm.updateType();
          let {first_name,last_name} = response.data.data;
          if(response.data.data.consult_date || response.data.data.consult_date != ''){
            vm.startDate = moment(response.data.data.consult_date).format('MM/DD/YYYY');
          }
          vm.patientName = first_name + ' ' + last_name;
          vm.stalledDismiss = !response.data.data.stalled_dimiss;

          const today = new Date().toISOString().slice(0, 10); // Today date in format YYYY-MM-DD
          vm.snoozed = new Date(response.data.data.snooze_date).getTime() >= new Date(today).getTime();

          vm.checklistStatus = response.data.data.checklist_deactivate;
          vm.autoCalculateDueDate = response.data.data.due_date_auto_cal === 1 ? true : false ;
          vm.getChecklistTableData();
          vm.getStagesWithDays();
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Surgery Date',
            message: message
          });
        })
    },

    updateSurgeryDate(){
      let vm = this;
      axios.post(`${this.$store.getters.getBaseUrl}/api/surgery-date?patientId=${vm.request.patientId}&tempStage=${vm.request.type}&surgeryDate=${moment(vm.surgeryDate,'MM-DD-YYYY').format('YYYY-MM-DD')}`,)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          if (moment(vm.surgeryDate,'MM-DD-YYYY') > moment.now()){
            vm.request.tab = '0';
            vm.request.type= 1;
          }
          else  {
            vm.request.tab = '1';
            vm.request.type= 2;
          }
          vm.updateType();
          let index = 'checklistForm_'  + this.request.tab;
          vm.getChecklistTableData();
          vm.$notify.success({
            title: 'Surgery Date',
            message: 'Surgery Date Updated Successfully'
          });
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Update surgery Date',
            message: message
          });
          vm.loaders.alerts = false;
        })
    },

    updateStartDateInDb(){
      let vm = this;
      let sd = vm.startDate ? moment(vm.startDate,'MM-DD-YYYY').format('YYYY-MM-DD') : null;
      axios.post(`${this.$store.getters.getBaseUrl}/api/start-date?patientId=${vm.request.patientId}&tempStage=${vm.request.type}&consultationDate=${sd}`,)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.$notify.success({
            title: 'Checklist',
            message: 'Start Date Updated Successfully'
          });
          vm.getChecklistTableData();
        })
        .catch((error) => {

          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Update Start Date',
            message: message
          });
          vm.loaders.alerts = false;
        })
    },

    updateStartDate(data){
        if (this.request.type == 2) {
          this.surgeryDate = data.date ? moment(new Date(data.date)).format('MM-DD-YYYY') : '';
          this.updateSurgeryDate();
        }
        else {
          this.startDate = data.date ? moment(new Date(data.date)).format('MM-DD-YYYY') : '';
          this.updateStartDateInDb();
        }
        let index = 'checklistForm_'  + this.request.tab;
        this.getChecklistTableData();
    },

    updateChecklistItem(element){
    },

    createChecklistItem(element){
      let vm = this;
      // vm.loaders.items = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklists/store`,
        {
          patientId:vm.request.patientId,
          temp_stage:vm.request.type,
          checklists:vm.request.newChecklistItems
        }
      )
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.request.newChecklistItems = [];
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Create Checklist Item',
          message: message
        });
      }).finally(() => {
        // vm.loaders.items = false;
      });
    },

    onChangeReason(element){
    },

    onSaveChangeStatus() {
      let reason = this.deactivateOptions[this.selectedReason].value;
      this.toggleChangeStatusModal();
      this.deactivateChecklist(this.request.patientId,1,reason);
    },

    activateChecklist(){
      this.deactivateChecklist(this.request.patientId,0);
    },

    deactivateChecklist(patientId,deactivate,reason=null){
      let data = {
        patientId:this.request.patientId,
        deactivate:deactivate,
      }
      if(reason!==null){
        data['reason'] = reason;
      }
      let vm = this;
      axios
        .post(
          `${this.$store.getters.getBaseUrl}/api/deactivate-checklist`,data
        )
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.checklistStatus = response.data.data.checklist_deactivate;
          vm.$notify.success({
            title: 'Checklists Status',
            message: 'Status Changed Successfully'
          });
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Checklists Status',
            message: message
          });
          // vm.loaders.alerts = false;
        });
    },

    toggleChangeStatusModal() {
      this.modals.changeStatusModal = !this.modals.changeStatusModal;
    },

    onConfirmChecklistAlertModal() {
      let vm = this;
      vm.request.notification = "Your checklist has been updated.";
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklist/update/notification`,
        {
          patientId:vm.request.patientId,
          notification:vm.request.notification
        }
      )
      .then((response)=>{
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        let message = response.data.message;
        vm.$notify.success({
          title : 'Checklists Alert Patient',
          message: message
        })
      })
      .catch((error)=>{
        let message = 'Something went wrong please try again in few minutes.';
        if(error.response) {
          let errors = error.response.data.errors;
          let firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        vm.$notify.error({
          title : 'Checklists Alert Patient',
          message: message
        })
      });
      this.toggleChecklistAlertModal();
    },

    toggleChecklistAlertModal() {
      this.modals.checklistAlertModal = !this.modals.checklistAlertModal;
    },

    togglePrintChecklistModal(){
      this.modals.printChecklistModal = !this.modals.printChecklistModal;
      this.getChecklistDataList();
    },

    getInsuranceCoordinators(){
      let vm = this;
      axios
        .get(
          `${vm.$store.getters.getBaseUrl}/api/insurance-coordinators/get-ics-for-select`,
        )
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.insuranceCoordinators = response.data.data;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Insurance Coordinators',
            message: message
          });
        })
    },

    onSelectInsuranceCoordinator(){
      let vm = this;
      axios
        .get(
          `${vm.$store.getters.getBaseUrl}/api/insurance-coordinators/${vm.printChecklist.insuranceCoordinator}`,
        )
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.insuranceCoordinator = response.data.data;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Insurance Coordinators',
            message: message
          });
        })
    },

    dataLoaded(val){
      if (val){
        this.loadedTabsCount = this.loadedTabsCount + 1;
      }
      if(this.loadedTabsCount === this.tabsCount ){
        this.loaders.checklists = false
      }
    },

    resetLoadedTabsCounts(){
      this.loadedTabsCount = 0;
    },

    /**
     * Generate and return checklist Table data HTML
     * @returns {string}
     */
    generateChecklistItemsHTML() {
      const insuranceCoordinatorContent = this.response.insuranceCoordinator.content ? (this.response.insuranceCoordinator.content) : "";
      let checklistTableHTML = '<div class="table-responsive" id="showTable">' +
        insuranceCoordinatorContent +
        '<table class="tblPrintChecklist" style="width: 100%">' +
        '<thead style="height: 59px"> ' +
        '<tr style="height: 59px; border-bottom: 0.5px solid #E6E6E6"> ' +
        '<th class="tbl-ic-th-custom-styling" style="padding-left:13px; width: 66%; text-align: left; color: #000000; font: normal normal 600 17px/25px Open Sans;">Checklist Items</th> ' +
        '<th class="tbl-ic-th-custom-styling" style="padding-left:13px; width: 17%; text-align: left; color: #000000; font: normal normal 600 17px/25px Open Sans;">Complete Before</th> ' +
        '<th class="tbl-ic-th-custom-styling" style="padding-left:13px; width: 17%; text-align: left; color: #000000; font: normal normal 600 17px/25px Open Sans;">Completed Date</th> ' +
        '</tr>' +
        '</thead>' +
        '<tbody>'

      let key = ''
      for (key in this.response.checklistDataList) {
        checklistTableHTML += '<tr>' +
          '<td style="width: 66%; padding-left:13px; text-align: left; font: normal normal 500 14px/25px Open Sans; color: #000000; letter-spacing: 0.48px;">' + this.response.checklistDataList[key].todo_name + '</td>' +
          '<td style="width: 17%; padding-left:13px; text-align: left; font: normal normal 500 14px/25px Open Sans; color: #000000; letter-spacing: 0.48px;">' + this.response.checklistDataList[key].todo_due_date + '</td>' +
          '<td style="width: 17%; padding-left:13px; text-align: left; font: normal normal 500 14px/25px Open Sans; color: #000000; letter-spacing: 0.48px;">' + this.response.checklistDataList[key].is_completed_date + '</td>' +
          '</tr>'
      }
      checklistTableHTML += '</tbody></table></div>'
      return checklistTableHTML
    },

    onConfirmPrintChecklistModal(){
      // Get HTML to print from element
      let st = document.getElementById('showTable');
      st.style.display = 'block';
      const prtHtml = this.generateChecklistItemsHTML()
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }
      var pName = this.patientName;

      this.response.insuranceCoordinator = {};
      this.printChecklist.insuranceCoordinator = '';
      st.style.display = 'none';
      this.modals.printChecklistModal = false;

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
        ${stylesHtml}
        <style>
         td {
          padding: 5px;
        }
        table, th, td {
            border-bottom: 1px solid #ddd;
        }
        </style>
        </head>
        <body style="margin-left: 10px; margin-right: 10px">
        <img src="../img/new_logo.svg" width="210" height="25" style="margin-top: 20px; margin-bottom: 8px">
        <div style="height: 20px; margin-bottom: 20px;">
            <p style="font:normal normal 600 17px/25px Open Sans; color: #000000; margin-top: 0px; padding-top: 0px">${pName}</p>
        </div>
         ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.document.querySelector('img').addEventListener('load', () => {
        WinPrint.focus();
        WinPrint.print();
      });
    },

    toggleChangeStageModal(element){
      this.currentStage = element;
      this.modals.changeStageConfirmationModal = !this.modals.changeStageConfirmationModal;
    },

    onConfirmChangeStageModal(){
      let vm = this;
      axios.post(vm.$store.getters.getBaseUrl + '/api/update-patients-pipeline-stage?patientId=' + vm.request.patientId + '&stageId=' + vm.currentStage.id).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.modals.changeStageConfirmationModal = !vm.modals.changeStageConfirmationModal;
        vm.getStagesWithDays();
      }).catch(error => {
        console.error(error.response);
      }).finally(() => {
      });
    },

    getStagesWithDays(){
      let vm = this;
      axios.get(vm.$store.getters.getBaseUrl + '/api/patient-pipeline-stage-days?patientId=' + vm.request.patientId).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.pbCurrentStage = response.data.data.pipeline_stage.order_key;
        vm.pbStages = response.data.data.stages;
        vm.pbStageMaxLength = (100/Object.keys(response.data.data.stages).length);
      }).catch(error => {
        console.error(error.response);
      }).finally(() => {
      });
    },

    onChangeAutoCalcDueDate(data){
      let vm = this;
      axios.post(`${this.$store.getters.getBaseUrl}/api/patients/update-auto-calc-due-date`,
        {
          patientId: vm.request.patientId,
          autoCalcDueDateStatus: data.acdd,
        }
      )
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklists Auto Calculate',
          message: message
        });
      }).finally(() => {

      });
    },

    getChecklistDataList() {
      let vm = this;
      axios.get(vm.$store.getters.getBaseUrl + `/api/checklists?patientId=${vm.request.patientId}&temp_stage=${vm.request.type}`)
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.checklistDataList = response.data.data.data;
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Patients Checklists Retrieval ',
          message: message
        });
      }).finally(() => {
        // vm.loaders.items = false;
      });
    },

  },

  watch: {

  },
}
</script>

<style lang="scss" scoped>
.note-label {
  font-size: 14px;
  color: black;
  opacity: 0.5;
  font-weight: 500;
}
.dt-bg-clr {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #2F597A;
  border-radius: 20px;
  padding:4px 0px 4px 15px;
  margin-left: 15px;
  text-align: left;
  font: normal 14px/24px Open Sans;
  letter-spacing: 0.5px;
  color: whitesmoke;
  opacity: 1;
  font-weight: bold;
  cursor: pointer;
  width: max-content;
}
.txt-clr {
  color: #94B527;
}
.el-date-editor  input[type="text"]{
  background-color: transparent;
  border: none;
  outline:none;
  color:white;
}

/*.el-date-editor input[type="text"]{*/
/*  color: #94B527;*/
/*}*/

.btn-purple {
  color: white;
  background-color: #8898aa;
  border-radius: 9px;
}

.btn-green-theme {
  background: #94B527 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: 0px;
  color: white;
  opacity: 1;
}

.radio-button-card{
  padding: 8px 11.5px !important;
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  opacity: 1;
  width: 228px;
  height: 53px;
}
.radioButtonCard :focus {
  background-color: #94B527 !important;
}

.base-radio-text {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #525F7F;
  opacity: 1;
}
.btnAlert {
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #F5365C;
  opacity: 1;
  padding: 8px 9px 8px 9px !important;
}
.dtpicker {
  color: #94B527;
  cursor: pointer;
}

::v-deep .el-input__inner{
  height: 26px;
  border-radius: 0;
  line-height: 26px;
}

::v-deep .dropdown-toggle-no-caret{
  height: 25px;
  padding-top: 0px;
  padding-right: 4px;
  padding-left: 4px;
}
::v-deep .card{
  position: initial;
}

.heading-datepicker{
  ::v-deep .date-container{
    border: 1px solid transparent;
  }
  ::v-deep .calendar-button{
    border: 1px solid transparent;
  }
  ::v-deep .date-input{
    .el-input__inner{
      border: 1px solid transparent;
      background-color: transparent;
      color: white;
      font-weight: bold;
      &::placeholder{
        color: white;
      }
    }
  }
}
</style>

<style>

.no-border-input:focus{
  outline:none;
}

.radioButtonCard .custom-control-label {
  vertical-align: unset !important;
}
.dt-bg-clr {
  text-align: left;
  font: normal 14px/24px Open Sans;
  letter-spacing: 0.5px;
  color: #32325D;
  opacity: 1;
  font-weight: bold;
}
.dt-bg-clr .el-date-editor  input[type="text"]{
  background-color: transparent;
  border: none;
  outline:none;
  color:white;
  font-weight: bold;
  cursor: pointer;
}
.dt-bg-clr .el-date-editor{
  width: 125px;
  cursor: pointer;
}

.checklist-alert-modal .modal-body {
  padding-top: 0px;
}
.alert-message {
  width: 350px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.dt-bg-clr .el-input__inner {
  padding-right: 0px;
}

.base-radio-cls .custom-control-label{
  display: flex;
  align-items: center;
  justify-content: left;
}

.base-radio-cls .custom-control-label span {
  top:0px;
}

.base-radio-cls .custom-control-label::before,.base-radio-cls .custom-control-label::after{
  top:0px;
}

.base-radio-cls .custom-control-input:checked ~ .custom-control-label::before  {
  border-color: #2F597A;
  background-color: #2F597A;
}
.surgery_date_input .el-input__inner{
  border-color: #2F597A;
  background-color: #2F597A;
  color: white;
}

.deactivation-banner {
  position: fixed;
  bottom: 0;
  background-color: #FEE6EB;
  box-shadow: 0px -2px 5px #8898AA40;
  opacity: 1;
  padding: 15px 15px 15px 15px;
}

.deactivation-banner-p {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.customInputStyle .input_height .el-input__inner {
  /*padding-left: 10px;*/
  color: #525F7F;
}
.customInputStyle .input_height .el-input__prefix {
  /*right: 0;*/
}
.customInputStyle .input_height .el-input__prefix .el-input__icon {
  /*float: right;*/
  margin-right: 2px;
  color: #525F7F;
}

.printModalInfo {
  min-height: 100px;
  margin-left: 16px;
  margin-right: 16px;
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border: 1px solid #DEE2E6;
}


.tblPrintChecklist{
  overflow: auto;
  max-height: 200px;
  width: 100%;
  border: 1px solid #DEE2E6;
  text-align: center;
}

.clr-blue {
  background-color: #B2FFDE;
}

.clr-red {
  background-color: #FFACBD;
}

.tbl-ic-td-custom-styling {
  width: 25%;
  padding: 2px;
}
.printChecklistModal .modal-body{
  padding-top: 1px;
}
.printChecklistModal .close > span{
  color: black;
}



.confirmation-message {
  width: 285px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}
/*
Progress Bar
*/

.breadcrumb.wizard {
  padding: 0px;
  background: #94B527;
  list-style: none;
  overflow: hidden;
  margin-top: 20px;
  font-size: 10px;
  width: 100%;
}
.breadcrumb.wizard>li+li:before {
  padding: 0;
}
.breadcrumb.wizard li {
  float: unset;
  min-height: 62px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background: white;
}
.breadcrumb.wizard li.active{
  background: #832C44;
}
.breadcrumb.wizard li a {
  text-decoration: none;
  padding: 10px 0 10px 45px;
  position: relative;
  display: block;
  width:100%;

  text-align: left;
  font: normal normal 600 11px/15px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}
.breadcrumb.wizard li.active a{
  color: white;
}
.breadcrumb.wizard li:not(:last-child) a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.breadcrumb.wizard li:not(:last-child) a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid black;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
  opacity: .2;
}
.breadcrumb.wizard li.active a:after {
  border-left: 30px solid #832C44;
}

.breadcrumb.wizard li:first-child a {
  padding-left: 15px;
}
.pb-internal-span {
  display: block;
  width: 100%;
  text-align: left;
  font: normal normal bold 14px/19px Open Sans;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  padding-top: 6px;
}
.pb-internal-a {

}

.btn-pink, .footer-button .btn-pink:hover, .footer-button .btn-pink:active {
  color: white;
  background: #F5365C;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  opacity: 1;
}


.btn-gray, .footer-button .btn-gray:hover, .footer-button .btn-gray:active{
  color: white;
  background: #8898AA;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  opacity: 1;
}

.change-stage-confirmation-modal .modal-body {
  margin-top: 0px;
  padding-top: 0px;
}

.custom-checklist-table .el-tabs--border-card>.el-tabs__content {
  padding: 15px !important;
}

</style>
