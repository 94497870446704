<template>
  <vc-date-picker class="inline-block h-full" @dayclick="focusOutEvent" v-model="date"
                  :min-date="minDate"
                  :max-date="maxDate"
                  is-required
                  :popover="{ positionFixed: true }"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div class="flex items-center date-container">
        <el-input class="date-input"
                  v-model="displayDate" :placeholder=placeholder maxlength="10"
                  @input="validation" @change="focusOut"
                  v-mask="'99/99/9999'"
        ></el-input>
        <button
          :style="{height : iconWidth}"
          class="calendar-button"
          type="button"
          @click="togglePopover()"
        >
          <svg v-if="!showImageIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            class="w-4 h-4 fill-current"
          >
            <path
              d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
            ></path>
          </svg>
          <img v-else width="25" height="25" src="/img/Calendar.svg" alt="">
        </button>
      </div>
    </template>
  </vc-date-picker>
</template>

<script>
import moment from "moment";
export default {
  name: "CustomDatePicker",
  props: ['inputData','minDate','maxDate','format','icon_width', 'today', 'showPlaceholder', 'showImageIcon'],
  data() {
    return {
      iconWidth:'auto',
      dateFormat:'MM/DD/YYYY',
      placeholder: this.showPlaceholder ?? 'MM/DD/YYYY' ,
      date: null,
      displayDate : null
    }
  },
  created() {
    let date = moment(this.inputData, this.dateFormat);
    // let date = moment(this.inputData, 'YYYY/MM/DD');
    this.date =  this.today ? new Date() : date.isValid() ?  date.format('YYYY-MM-DD') : '';
    this.displayDate = date.isValid() ?  date.format('MM/DD/YYYY') : '';
  },
  methods: {
    focusOut() {
      this.displayDate = moment(this.displayDate,'MM/DD/YYYY').isValid() ? moment(this.displayDate,'MM/DD/YYYY').format('MM/DD/YYYY') : '';
      if (this.minDate){
        if (moment(this.displayDate) < moment(this.minDate)) {
          this.displayDate = moment().format('MM/DD/YYYY');
        }
      }
      if (this.maxDate){
        if (moment(this.displayDate) > moment(this.maxDate)) {
          this.displayDate = moment().format('MM/DD/YYYY');
        }
      }
      this.$emit('update:inputData', this.displayDate);
      this.$emit('change',this.displayDate);
    },
    validation(evt) {
      evt = (evt) ? evt : window.event;
      let keyCode = (evt.which) ? evt.which : evt.keyCode;
      if((keyCode < 48 || keyCode > 57) && keyCode !== 47){
        evt.preventDefault();
      }
      if (this.displayDate) {
        this.changeFormat();
      }
    },
    changeFormat() {
      let v = this.displayDate;
      if (v.match(/^\d{2}$/) !== null || v.match(/^\d{2}\/\d{2}$/) !== null) {
        this.displayDate = v + '/';
      }
    },
    focusOutEvent(){
      let date = moment(this.date, 'YYYY-MM-DD');
      date = date.isValid() ?  date.format('MM/DD/YYYY') : '';
      this.$emit('update:inputData',date);
      this.$emit('change',date);
    }
  },
  watch: {
    inputData(val){
      let date = moment(this.inputData, 'MM/DD/YYYY');
      this.displayDate = date.isValid() ?  date.format('MM/DD/YYYY') : '';
      this.date = date.isValid() ?  date.format('YYYY-MM-DD') : new Date();
    },

    icon_width(newVal) {
      this.iconWidth = newVal
    },

    format(newVal) {
      this.dateFormat = newVal
    }
  }
}
</script>
<style lang="scss">
.vc-highlight {
  background-color: #832C44 !important;
}

.date-container{
  .date-input{
    input{
      border: none;
    }
  }
}
</style>
<style lang="scss" scoped>

.calendar-button{
  width: 26px;
  background-color: transparent;
  border: none;
  border-left: none;
  padding-bottom: 0;
  color: #172B4D;
}
.date-input{
  width: calc(100% - 32px);
  ::v-deep .el-input__inner{
    border-right: none;
  }
}
.date-container{
  min-width: 142px;
  border: 1px #DCDFE6 solid;
}
.is-invalid{
  .date-container{
    border-color: #fb6340;
  }
}
</style>
