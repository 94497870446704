<template>
  <div class="">
    <div class="modals">
      <modal :show.sync="modals.checklistItemsModal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold" id="checklistItemsModal">Add Checklist Items</h4>
        </template>
        <template slot="default">
          <div class="mt--4">
            <base-input label="Checklist Item" class="checklist-item-label">
              <el-select
                class="w-100"
                placeholder="Select Checklist Item"
                v-model="request.selectedModalItem"
                @change="onSelectedChecklistItem"
                filterable>
                <el-option
                  v-for="option in response.checklistItemsList"
                  class="select-danger"
                  :value="option"
                  :label="option.description"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <template v-if="request.selectingChecklistItem.length > 0">
              <div class="select-items-label mt--3 mb-2">Selected Items</div>
              <div class="selected-items scrollList">
                <div class="d-flex justify-content-between pt-3 pb-3 pl-4 pr-4 selected-item"
                     v-for="selectingItem in request.selectingChecklistItem"
                     :key="selectingItem.id">
                  <div class="added-checklist-item">
                    {{ selectingItem.description }}
                  </div>
                  <div @click="onRemoveSelectedItem(selectingItem)">
                    <img src="/img/icons/buttons/remove.svg" alt="*">
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template slot="footer">
          <div class="">
            <base-button type="secondary" @click="modals.checklistItemsModal=false">Cancel</base-button>
            <base-button class="btn-primary-action pl-5 pr-5 br-4" type="submit" @click="onAddChecklistItems">
              Add
            </base-button>
          </div>
        </template>
        <!-- Notification Modal Footer Finished -->
      </modal>
      <el-dialog
        title="Add Template"
        :visible.sync="modals.checklistTemplateModal"
        width="30%"
        :before-close="handleClose"
        @open="getChecklistTemplates"
        :close-on-click-modal="false">
        <validation-observer v-slot="{handleSubmit}" ref="formAddTemplateValidator">
          <form role="form" @submit.prevent="handleSubmit(onAddTemplate)">
            <div class="mt--4">
              <base-input label="Template" name="template" :rules="{required: true}">
                <el-select
                  class="w-100"
                  :rules="{required: true}"
                  placeholder="Select A Template"
                  v-model="templateForm.template"
                  @change="onSelectChecklistTemplate">
                  <el-option
                    v-for="option in response.checklistTemplatesList"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="text-right mt-4">
              <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
              <base-button class="btn-primary-action pl-5 pr-5 br-4" native-type="submit" type="success">Add</base-button>
            </div>
          </form>
        </validation-observer>
      </el-dialog>
    </div>
    <div class="forms">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" @submit.prevent="handleSubmit(onSubmit)">
          <el-table-draggable
            handle=".handle"
            @drop="updateSortList"
            v-loading="table_loader"
          >
          <div class="el-table-upper-scroll" ref="upperScroll" @scroll="scrollTwo">
            <div class="__scroll" ref="upperScrollWidth">
            </div>
          </div>
            <el-table
              class="table-responsive table-flush checklist-items-table checklist-table patient-checklist-table"
              header-row-class-name="thead-light"
              width="100%"
              :show-header="true"
              :data="response.checklistTableData"
              :row-class-name="row"
              ref="elTable"
            >
              <el-table-column align="left" :class-name="btnDraggable" width="50">
                <template slot-scope="scope">
                  <div class="handle" v-if="scope.row.id !==''">
                    <img src="/img/icons/buttons/handle.svg" alt="+">
                  </div>
                </template>
              </el-table-column>

              <el-table-column min-width="220px" label="Checklist Name">
                <template slot-scope="scope">
                  <el-input class="input_height" v-model="scope.row['todo_name']" @blur="updateChecklistItemName(scope)"/>
                </template>
              </el-table-column>

              <el-table-column label-class-name="justify-content-center" min-width="220px"  label="Complete Before">
                <template slot-scope="scope">
                  <custom-date-picker
                    :inputData.sync="scope.row['todo_due_date']"
                    :minDate= "new Date()"
                    :show-placeholder="''"
                    :show-image-icon="false"
                    @change="updateCompletedBefore(scope.row)">
                  </custom-date-picker>
                </template>
              </el-table-column>

              <el-table-column label-class-name="justify-content-center" min-width="220px" label="Completed">
                <template slot-scope="scope">
                  <custom-date-picker
                    class="dp-hide-input-div-cd"
                    :inputData.sync="scope.row['is_completed_date']"
                    :maxDate="new Date()"
                    :show-placeholder="''"
                    :show-image-icon="false"
                    @change="updateCompletedBefore(scope.row)">
                  </custom-date-picker>
                </template>
              </el-table-column>

              <el-table-column label-class-name="justify-content-center" label="Completed By" min-width="160px">
                <template slot-scope="scope">
                  <el-select :disabled="isDisabled(scope.$index)" class=" input_height" v-model="scope.row['checked_by']" size="small" @change="updateCheckedBy(scope.row)">
                    <el-option
                      v-for="option in dropdowns.checkedByOptions"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.id">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column width="180px" label-class-name="justify-content-center" align="right" :class-name="customButtonStyle">
                <div slot-scope="scope" class="d-flex">
<!--                  <el-tooltip-->
<!--                    popper-class="tooltip-popper-class-width-400"-->
<!--                    class="item" effect="dark"-->
<!--                    :content="'One checkmark indicates the patient cannot check this item off. ' +-->
<!--                     'When both checkmarks are checked both you and the patient can check off the item.'"-->
<!--                    placement="bottom-start"-->
<!--                    :visible-arrow="false"-->
<!--                  >-->
<!--                    <img class="pointer mr-1" :src="getImageSource(scope.row['patient_can_mark'])" alt=""-->
<!--                         @click="updatePatientCanMark(scope, 'patient_can_mark')">-->
<!--                  </el-tooltip>-->
                  <el-tooltip
                    class="item" effect="dark"
                    :content="'Patient will see any notes you add here when they click on this item in their checklist'"
                    placement="bottom-start"
                    :visible-arrow="false"
                  >
                    <img class="pointer mr-2" src="/img/icons/buttons/detail.svg" alt=""
                         @click="showChecklistItemDetailModal(scope.row.id, scope.row.todo_detail)">
                  </el-tooltip>
                  <div v-for="(action, index) in buttons.actions" :key="index">
                    <el-tooltip
                      class="item" effect="dark"
                      :content="'Delete'"
                      placement="bottom-start"
                      :visible-arrow="false"
                    >
                      <actions
                        class="pointer"
                        @handleDelete="handleDelete(scope.$index, scope.row)"
                        :action="action.action"
                        :src="action.src"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </el-table-column>
            </el-table>
          </el-table-draggable>
        </form>
      </validation-observer>
    </div>

    <div class="row mt-2 customDpStyle">
      <div class="col" style="z-index: 2;">
        <base-button
          type="secondary"
          class="pt-1 pb-1 pl-1 pr-3 mt-2 font-weight-normal add-checklist-buttons"
          id="add-row"
          @click="addChecklistItemRow">
          <img src="/img/icons/buttons/plus.svg" alt="+">
          Add Row
        </base-button>
        <base-button
          type="secondary"
          class="pt-1 pb-1 pl-1 pr-3  mt-2 font-weight-normal add-checklist-buttons"
          @click="toggleChecklistItemModal"
        >
          <img src="/img/icons/buttons/plus.svg" alt="+"> Add Checklist Item
        </base-button>
        <base-button
          type="secondary"
          class="pt-1 pb-1 pl-1 pr-3 mt-2 font-weight-normal add-checklist-buttons"
          @click="toggleChecklistTemplateModal"
        >
          <img src="/img/icons/buttons/plus.svg" alt="+"> Add Template
        </base-button>
        <span class="temporary btn pt-1 pb-1 pl-1 pr-3 mt-2 font-weight-normal">
          <label class="mr-2 ml-4 mb-0 lbl-start-date">
            {{patientStage==1 || patientStage==3 ? 'Start Date' : 'Surgery Date'}}
          </label>
        </span>
        <span v-if="patientStage==1 || patientStage==3" class="temporary btn pt-1 pb-1 pl-1 pr-3 mt-2 font-weight-normal checklist-start-date">
          <custom-date-picker
            :inputData.sync="startDate"
            :today="true"
            :show-placeholder="''"
            :show-image-icon="false"
            @change="onChangeStartDate">
          </custom-date-picker>
        </span>
        <span  v-if="patientStage==2" class="temporary btn pt-1 pb-1 pl-1 pr-3 mt-2 font-weight-normal checklist-start-date">
          <custom-date-picker
            :inputData.sync="formSurgeryDate"
            :show-image-icon="false"
            @change="onChangeFormSurgeryDate">
          </custom-date-picker>
        </span>
        <span class="temporary btn pt-1 pb-1 pl-1 pr-3 mt-2 font-weight-normal">
          <el-checkbox class="pr-4 mt-1 cb-auto-clc-due-date" v-model="autoCalculateDueDate" @change="onChangeAutoCalcDueDate">Calculate Complete Before Date</el-checkbox>
        </span>
      </div>

    </div>
    <div class="row accd-cb-div">
      <div class="col text-right">

      </div>
    </div>
    <checklist-item-detail-modal
      :item-id="checklistItemId"
      :detail-text="checklistItemDetail"
      :model-sync="checklistItemDetailModal"
      @hideChecklistItemDetailModal="hideChecklistItemDetailModal"
      @saveChecklistItemTemplateDetail="saveChecklistItemTemplateDetail"
    />
  </div>

</template>

<script>
import _, {lastIndexOf} from "lodash";
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import pickerOptions from "@/constants/pickerOptions";
import moment from "moment";
import ElTableDraggable from 'element-ui-el-table-draggable';
import {Option, Select, Table, TableColumn} from "element-ui";
import Actions from "@/components/Actions/Actions";
import CustomDatePicker from "../../Components/CustomDatePicker";
import ChecklistItemDetailModal from "@/views/Pages/Checklists/ChecklistItemDetailModal";

export default {
  name: "ChecklistForm",
  props: ['checklistItems','checklistTableData' ,'checklistTemplates', 'loader', 'buttonText',
    'tempStage', 'patientId','autoCalcDueDate','surgeryDate','consultDate'],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Actions,
    ElTableDraggable,
    CustomDatePicker,
    ChecklistItemDetailModal
  },
  data() {
    return {
      indexOfAddingItem: -1,
      checklistItemId: '',
      checklistItemDetail: '',
      checklistItemDetailModal: false,
      table_loader: false,
      modals: {
        checklistItemsModal: false,
        checklistTemplateModal: false,
      },
      templateForm: {
        template: '',
        date: ''
      },
      checklistItemsForm: {},
      request: {
        selectedChecklistItems: [],
        selectingChecklistItem: [],
        newChecklistItems: [],
        newEmptyChecklistItems: [],
        toUpdateChecklistItems: [],
        toRemoveChecklistItems: [],
        templateDetailValue: '',
        detailBeforeCancel: '',
        selectedModalItem: '',
        toUpdateChecklistItem: '',
        toDeleteChecklistItem: '',
        checklistItemId: '',
      },
      response: {
        list: [],
        checklistItemsList: [],
        checklistTemplatesList: [],
        checklistTableData: [],
        index: 0,
      },
      buttons: {
        actions: [
          {
            src: '/img/icons/buttons/remove.svg',
            action: 'delete'
          }
        ],
      },
      tables: {
        checklistItemsTable: [
          {
            prop: "todo_name",
            label: "Checklist Name",
            type: 'text',
            minWidth: 180,
          },
          {
            prop: "todo_due_date",
            label: "Complete Before",
            type: 'completedBefore',
            minWidth: 120
          },
          {
            prop: "is_completed_date",
            label: "Completed",
            type: 'isCompletedDate',
            minWidth: 120
          },
          {
            prop: 'checked_by',
            label: 'Completed By',
            type: 'select',
            minWidth: 120
          }
        ]
      },
      pickerOptions: {
        dtGTOETT: pickerOptions.dtGTOETT,
        dateGreaterThanToday: pickerOptions.dateGreaterThanToday,
        dateLessThanToday: pickerOptions.dateLessThanToday,
      },
      startDate: '',
      formSurgeryDate: '',
      autoCalculateDueDate: false,
      patientStage:1,
      unsaved_data_key_counter:0,

      //
      today: moment().format('YYYY-MM-DD'),
      customButtonStyle:'customButtonStyle',
      btnDraggable:'btnDraggable',
      customInputStyle:'customInputStyle',
      dropdowns : {
        checkedByOptions : [
          {
            id:1,
            value:'',
            label:'Incomplete',
          },
          {
            id:2,
            value:'Program',
            label:'Program',
          },
          {
            id:3,
            value:'Patient',
            label:'Patient',
          },{
            id:4,
            value:'Waiting for paperwork',
            label:'Waiting for paperwork',
          },
        ],
      },
      oldCompleteBeforeDate:moment(this.today).format('MM/DD/YYYY'),
      oldCompletedDate:moment(this.today).format('MM/DD/YYYY'),
      selectedRow:{},
      selectedColumn:''
    }
  },

  computed: {
    getContentValue() {
      let value = ""
      if (this.request.selectedChecklistItems.length > 0) {
        value = _.cloneDeep(this.request.selectedChecklistItems[this.response.index]).template_detail;
      }
      return value;
    },
  },

  watch: {
    checklistItems(newVal) {
      this.response.checklistItemsList = [...new Map(newVal.map(item => [item['description'], item])).values()]
    },

    checklistTableData(newVal) {
      this.response.checklistTableData = newVal;
      this.$refs.upperScrollWidth.style.width = this.$refs.elTable.bodyWidth;
    },

    autoCalcDueDate(newVal) {
      this.autoCalculateDueDate = newVal;
    },

    surgeryDate(newVal) {
      if(this.patientStage == 2){
        this.formSurgeryDate = newVal;
      }
    },

    consultDate(newVal) {
      if(this.patientStage == 1 || this.patientStage == 3){
        this.startDate = moment(newVal,'MM-DD-YYYY').format('MM/DD/YYYY');
      }
    },

    tempStage(newVal) {
      this.patientStage = newVal;
      if(this.surgeryDate && this.surgeryDate!='0000-00-00'&& this.patientStage == 2) {
        this.formSurgeryDate = moment(this.surgeryDate,'MM-DD-YYYY').format('MM/DD/YYYY');
      }
      if(this.consultDate && this.patientStage == 1){
        this.startDate = moment(this.consultDate,'MM-DD-YYYY').format('MM/DD/YYYY');
      }
      this.$emit('getChecklistTableData');
      // this.getChecklistTableData();
    },
  },

  methods: {
    /**
     * Update patient can mark check for checklist item
     * @param scope
     * @param prop
     */
    updatePatientCanMark(scope, prop) {
      this.response.checklistTableData[scope.$index][prop] = !this.response.checklistTableData[scope.$index][prop]
      this.updateChecklistItem(scope.row)
    },

    /**
     * Get image source for patient can check
     * @param prop
     * @returns {string}
     */
    getImageSource(prop) {
      return prop == true ? '/img/green_only.svg' : '/img/green_gray.svg';
    },

    isDisabled(index) {
      return this.indexOfAddingItem == index
    },

    /**
     * Show the Checklist Item Detail Modal and pass the relevant info
     * @param id
     * @param text
     */
    showChecklistItemDetailModal(id, text) {
      this.checklistItemId = id
      this.checklistItemDetail = text
      this.checklistItemDetailModal = true
    },

    /**
     * Save the checklist item detail
     * @param id
     * @param detail
     */
    saveChecklistItemTemplateDetail({id, detail}) {
      this.hideChecklistItemDetailModal()
      this.response.checklistTableData.forEach(item => {
        if (item.id == id) {
          item.todo_detail = detail
          this.checklistItemDetail = detail
        }
      })
    },

    /**
     * Hide the Checklist Item Detail modal
     */
    hideChecklistItemDetailModal() {
      this.checklistItemDetailModal = false
    },

    handleStartDate(e) {
      if (moment(e).format('MM/DD/YYYY') == 'Invalid date') {
        this.startDate = '';
      }
    },

    onChangeStartDate() {
      let date = this.startDate;
      this.$emit('updateStartDate',{date});
      this.$emit('resetLoadedTabsCounts');
    },

    onContextStartDate(ctx) {

      if (ctx.selectedYMD != '') {
        this.startDate = ctx.selectedFormatted
        this.onChangeStartDate();
      }
    },

    handleSurgeryDate(e) {
      if (moment(e).format('MM/DD/YYYY') == 'Invalid date') {
        this.formSurgeryDate = '';
      }
    },

    onChangeFormSurgeryDate() {
      // this.handleSurgeryDate(this.formSurgeryDate);
      let date = this.formSurgeryDate;
      this.$emit('updateStartDate',{date});
      // this.$emit('resetLoadedTabsCounts');
      // this.getChecklistTableData();

    },

    onContextSurgeryDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.formSurgeryDate = ctx.selectedFormatted
        this.onChangeFormSurgeryDate();
      }
    },

    getChecklistTemplates() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + `/api/checklist/templates/list?temp_stage=${vm.patientStage}`)
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.checklistTemplatesList = response.data.data;
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Templates Retrieval',
          message: message
        });
      }).finally(() => {
      });
    },

    onSelectedChecklistItem(value) {
      this.request.selectingChecklistItem.push(value);
      this.response.checklistItemsList.splice(_.findKey(this.response.checklistItemsList, function (e) {
        return e.id === value.id;
      }), 1);
      this.request.selectedModalItem = '';
    },

    onSelectChecklistTemplate(value) {
    },

    addChecklistItemRow() {
      let highest_order_key = Math.max.apply(Math, this.response.checklistTableData.map(function (o) {
        return o.order_key;
      }));
      let checklistItem = {
        todo_name: '',
        todo_due_date: '',
        is_completed_date: '',
        checked_by: '',
        order_key: '',
        id: '',
        new_todo_setting_id : 0,
        patient_can_mark: true,
        template_id: 0,
        unsaved_data_key:moment().format('x'),
      };
      this.response.checklistTableData.push(checklistItem);
      // let newChecklistItem = {...checklistItem, index: this.response.checklistTableData.length - 1}
      // this.request.newEmptyChecklistItems.push(newChecklistItem);
    },

    onAddChecklistItems() {
      let vm = this;
      let highest_order_key = Math.max.apply(Math, this.response.checklistTableData.map(function (o) {
        return o.order_key;
      }))
      let selectedItems = vm.request.selectingChecklistItem;
      if (selectedItems.length > 0) {
        selectedItems.forEach(function (item) {
          let cTI = {};
          cTI['id'] = '';
          cTI['todo_name'] = item.description;
          cTI['todo_detail'] = item.template_detail;
          cTI['todo_due_date'] = '';
          cTI['is_completed_date'] = '';
          cTI['checked_by'] = '';
          cTI['order_key'] = highest_order_key + 1;
          cTI['new_todo_setting_id'] = 0;
          cTI['template_id'] = 0;
          cTI['patient_can_mark'] = item.patient_can_mark ?? true;
          cTI['unsaved_data_key'] = ++vm.unsaved_data_key_counter;
          vm.response.checklistTableData.push(cTI);
          vm.request.newChecklistItems.push(cTI);
        });
        vm.request.selectingChecklistItem = [];
        vm.storeChecklistInDb();
      }

      this.toggleChecklistItemModal();
    },

    async onAddTemplate() {
      const isTemplateFormValid = await this.$refs.formAddTemplateValidator.validate();
      if (isTemplateFormValid) {
        let vm = this;
        // vm.loaders.items = true;
        let highest_order_key = Math.max.apply(Math, this.response.checklistTableData.map(function (o) {
          return o.order_key;
        }))
        axios.get(this.$store.getters.getBaseUrl + `/api/checklist/templates/items?temp_stage=${vm.patientStage}&templateId=${vm.templateForm.template}`)
          .then(response => {
            vm.$store.commit('toggleSessionCheck', true)
            vm.$store.commit('updateSessionTime', 0)
            let fetchedItems = response.data.data.checklist_template_items;
            if (fetchedItems.length > 0) {
              fetchedItems.forEach(function (item) {
                let cTI = {};
                cTI['id'] = '';
                cTI['todo_name'] = item.description;
                cTI['todo_detail'] = item.template_detail;
                cTI['patient_can_mark'] = item.patient_can_mark ?? true;
                cTI['todo_due_date'] = vm.calculateDaysToComplete(vm.patientStage,item);
                cTI['is_completed_date'] = '';
                cTI['checked_by'] = '';
                cTI['order_key'] = ++highest_order_key;
                cTI['new_todo_setting_id'] = item.id;
                cTI['template_id'] = vm.templateForm.template;
                cTI['unsaved_data_key'] = ++vm.unsaved_data_key_counter;
                vm.response.checklistTableData.push(cTI);
                vm.request.newChecklistItems.push(cTI);
              });
              vm.storeChecklistInDb();
            }
            vm.toggleChecklistTemplateModal();
          }).catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Add Checklist Templates',
            message: message
          });
        }).finally(() => {
        });
      }
    },

    createChecklistItem(element) {
      this.request.newChecklistItems.push(element);
      this.storeChecklistInDb();
    },

    storeChecklistInDb() {
      let vm = this;
      if (vm.indexOfAddingItem && vm.indexOfAddingItem > -1) {
        let htmlElement = document.getElementsByClassName('patient-checklist-row' + vm.indexOfAddingItem)
        for (let i = 0; i < htmlElement.length; i++) {
          htmlElement[i].classList.add('row-disable')
        }
      }

      let unsaved_data_key_array = _.cloneDeep(vm.request.newChecklistItems);
      let checklists = _.cloneDeep(vm.request.newChecklistItems);
      checklists.forEach((val) => {
        delete val.unsaved_data_key;
      });
      vm.request.newChecklistItems = [];
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklists/store`,
        {
          patientId: vm.patientId,
          temp_stage: vm.patientStage,
          checklists: checklists
        }
      )
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          try {
            let responseChecklists = response.data.data;
            unsaved_data_key_array.forEach((value, index) => {
                value.id = responseChecklists[index].id;
                value.order_key = responseChecklists[index].order_key;
            });
            unsaved_data_key_array.forEach((value, index) => {
              vm.response.checklistTableData.forEach((val,ind) =>{
                if(value.unsaved_data_key == val.unsaved_data_key){
                  val.id = responseChecklists[index].id;
                  val.order_key = responseChecklists[index].order_key;
                }
              })
            });
          }
          catch (e) {
            console.log('exception',e);
          }
        }).catch(error => {
          console.log('error',error);
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Store Checklists',
          message: message
        });
      }).finally(() => {
        if (vm.indexOfAddingItem && vm.indexOfAddingItem > -1) {
          let htmlElement = document.getElementsByClassName('patient-checklist-row' + vm.indexOfAddingItem)
          for (let i = 0; i < htmlElement.length; i++) {
            htmlElement[i].classList.remove('row-disable')
          }
        }
        vm.indexOfAddingItem = -1
      });
    },

    updateChecklistItem(element) {
      this.handleCompleteBeforeDate(element);
      this.handleCompletedDate(element);

      if(element.checked_by == '' && element.is_completed_date != ''){
        element.checked_by = 'Program';
      }
      if (element.todo_name.trim() !== '') {
        if (element.id !== '') {
          this.request.toUpdateChecklistItem = element;
          this.updateChecklistItemInDb();
        } else {
          this.createChecklistItem(element);
        }
      }
    },

    updateChecklistItemInDb() {
      let vm = this;
      // vm.loaders.items = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklists/update`,
        {
          id: vm.request.toUpdateChecklistItem.id,
          todo_name: vm.request.toUpdateChecklistItem.todo_name,
          todo_due_date: vm.request.toUpdateChecklistItem.todo_due_date,
          is_completed_date: vm.request.toUpdateChecklistItem.is_completed_date,
          checked_by: vm.request.toUpdateChecklistItem.checked_by,
          patient_can_mark: vm.request.toUpdateChecklistItem.patient_can_mark,
        }
      )
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.request.toUpdateChecklistItem = undefined;
          //check and update the current item in the data array
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Update Checklists Items',
          message: message
        });
      }).finally(() => {
        // vm.loaders.items = false;
      });
    },

    handleDelete(index, element) {
      if (element.hasOwnProperty("id") && element.id !== '') {
        this.request.checklistItemId = element.id;
        this.response.checklistTableData.splice(index, 1);
        this.deleteChecklistFromDb();
      } else if (element.hasOwnProperty("id")) {
        this.response.checklistTableData.splice(index, 1);
      }
    },

    deleteChecklistFromDb() {
      let vm = this;
      // vm.loaders.items = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklists/destroy`,
        {
          id: vm.request.checklistItemId,
        }
      )
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          // vm.$emit('getChecklistTableData');
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Remove Checklist Item',
          message: message
        });
      }).finally(() => {
        // vm.loaders.items = false;
      });
    },

    onRemoveSelectedItem(selectedItem) {
      this.request.selectedChecklistItems.splice(_.findKey(this.request.selectedChecklistItems, function (e) {
        return e.id === selectedItem.id;
      }), 1);

      this.request.selectingChecklistItem.splice(_.findKey(this.request.selectingChecklistItem, function (e) {
        return e.id === selectedItem.id;
      }), 1);

      this.response.list.push(selectedItem);
    },

    clearTemplateForm() {
      this.templateForm.template = '';
      this.templateForm.date = '';
    },

    toggleChecklistItemModal() {
      this.modals.checklistItemsModal = !this.modals.checklistItemsModal;
    },

    toggleChecklistTemplateModal() {
      this.modals.checklistTemplateModal = !this.modals.checklistTemplateModal;
    },

    handleClose(done) {
      this.clearTemplateForm();
      this.$refs.formAddTemplateValidator.reset();
      this.modals.checklistTemplateModal = false;
    },

    onChangeAutoCalcDueDate(){
      let acdd = this.autoCalculateDueDate;
      this.$emit('onChangeAutoCalcDueDate', {acdd});
    },

    calculateDaysToComplete(ts,item){
      let date= '';
      if (item.days_to_complete != null && item.days_to_complete != 0) {
          if (ts == 1 || ts == 3) {
            date = this.startDate ? moment(new Date(this.startDate)).add(item.days_to_complete ? item.days_to_complete : 0, 'days').format('MM/DD/yyyy') : ''
          } else if (ts == 2) {
              date = this.surgeryDate ? moment(new Date(this.surgeryDate)).add(item.days_to_complete ? item.days_to_complete : 0, 'days').format('MM/DD/yyyy') : ''
          }
      }
      return date;
    },

    //Checklist Table Component
    focusOnCompleteBeforeDate(e){
      this.oldCompleteBeforeDate = e.target.value;
    },

    handleCompleteBeforeDate(e) {
      if (moment(e.todo_due_date).format('MM/DD/YYYY') == 'Invalid date') {
        e.todo_due_date = '';
      }else if (moment(e.todo_due_date) < moment(this.today)) {
        e.todo_due_date = this.oldCompleteBeforeDate;
      }
    },

    onContextCompleteBeforeDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.selectedRow[this.selectedColumn] = ctx.selectedFormatted
        this.updateCompletedBefore(this.selectedRow)
      }
    },

    focusOnCompletedDate(e){
      this.oldCompletedDate = e.target.value;
    },

    handleCompletedDate(e) {
      if (moment(e.is_completed_date).format('MM/DD/YYYY') == 'Invalid date') {
        e.is_completed_date = '';
      } else if (moment(e.is_completed_date) > moment(this.today)) {
        e.is_completed_date = this.oldCompletedDate;
      }
    },

    datePickerClicked(row,column){
      this.selectedRow = row;
      this.selectedColumn = column;
    },

    onContextCompletedDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.selectedRow[this.selectedColumn] = ctx.selectedFormatted
        this.updateChecklistItem( this.selectedRow)
      }
    },

    updateChecklistItemName(element) {
      this.indexOfAddingItem = (element.row.id == '') ? element.$index : -1
      this.updateChecklistItem(element.row);
    },

    updateCompletedBefore(element){
      this.updateChecklistItem(element);
    },

    updateCheckedBy(element){

      if((element.checked_by == 'Program'||element.checked_by == 'Patient') && (!element.is_completed_date)){
        element.is_completed_date = moment(new Date()).format('MM/DD/YYYY');
      }
      if(element.checked_by == ''){
        element.is_completed_date = '';
      }
      if(element.checked_by == 'Waiting for paperwork'){
        element.is_completed_date = '';
      }
      if(element.checked_by == '' && element.is_completed_date != ''){
        element.checked_by = 'Program';
      }
      if (element.todo_name.trim() !== '') {
        if (element.id !== '') {
          this.request.toUpdateChecklistItem = element;
          this.updateChecklistItemInDb();
        } else {
          this.createChecklistItem(element);
        }
      }
    },

    row({row, rowIndex}) {
      let classes = ''
      if (row.checked_by === 'Patient') {
        classes = "handle checklist-row-bg-yellow"
      } else if (row.checked_by === 'Program') {
        classes = "handle checklist-row-bg-green"
      } else if (row.checked_by === 'Waiting for paperwork') {
        classes = "handle checklist-row-bg-blue"
      } else {
        classes = "handle";
      }
      return classes + ' patient-checklist-row' + rowIndex
    },

    updateSortList(evt){
      // console.log('evt',evt.targetObject);
      let targetObj = evt.targetObject;
      let newIndex = evt.list.findIndex(f=>f.order_key === targetObj.order_key) + 1;
      let id = evt.targetObject.id;
      let idsArray  = evt.list.map(function (listItem) {
        if(listItem.id){
          return listItem.id;
        }
      });
      this.updateSort(id,newIndex,idsArray);
    },

    updateSort(id,newIndex,idsArray){
      let vm = this;
      this.$emit('startLoader');
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklists/update-indexes`,
        {
          id:id,
          newIndex:newIndex,
          patientId:vm.patientId,
          temp_stage:vm.tempStage,
          idsArray:idsArray,
        }
      )
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          this.$emit('getChecklistTableData');
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Update Checklists Sort',
          message: message
        });
      }).finally(() => {
          vm.scrollOne();
      });
    },
    scrollTwo(){
      this.$refs.elTable.$refs.bodyWrapper.scrollLeft = this.$refs.upperScroll.scrollLeft;
    },

    scrollOne(){
      const body = this.$refs.elTable.$refs.bodyWrapper;
      let vm = this;
      this.$refs.upperScrollWidth.style.width = this.$refs.elTable.bodyWidth;
      body.addEventListener('scroll',function(e){
        vm.$refs.upperScroll.scrollLeft = e.target.scrollLeft;
      });
    }
  },

  mounted: function () {
    this.scrollOne();
    this.autoCalculateDueDate = this.autoCalcDueDate;
    if(this.surgeryDate && this.surgeryDate!='0000-00-00'&& this.patientStage == 2) {
      this.formSurgeryDate = moment(new Date(this.surgeryDate)).format('MM-DD-YYYY');
    }
    if(this.consultDate && this.patientStage == 1){
      this.startDate = moment(this.consultDate,'MM-DD-YYYY').format('MM-DD-YYYY');
    }
  }
}
</script>

<style lang="scss">
.scrollList {
  overflow: auto;
  max-height: 185px;
  border-top: .5px solid #BDC4CC;
  border-left: .5px solid #BDC4CC;
  border-right: .5px solid #BDC4CC;
}

.select-items-label {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.selected-item {
  border-bottom: .5px solid #BDC4CC;
  opacity: 1;
}

.checklist-item-label {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

#checklistItemsModal {
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #32325D;
  opacity: 1;
}

.dp-height input {
  height: 30px !important;
}

.cls-start-date {
  text-align: right;
  justify-content: end;
  align-items: end;

}

.dp-height .el-input__prefix {
  margin-top: -5px;
}

.dp-height .el-input__suffix {
  margin-top: 2px;
}

.lbl-start-date {
  text-align: center;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}
.accd-cb-div .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #172B4D;
}
.cb-auto-clc-due-date .el-checkbox__label{
  text-align: left;
  font: normal normal normal 11px/15px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}
.temporary {
  transition: none !important;
}
.temporary:hover {
  border: none !important;
  box-shadow: none !important;
  transform: translateY(0px) !important;
  transition: none !important;
}

/*Checklist */
.checklist-row-bg-yellow{
  background-color: rgba(255, 205, 22, .36) !important;
}

.checklist-row-bg-green{
  background-color: rgba(148, 181, 39, .13) !important;
}
.checklist-row-bg-blue{
  background-color: rgba(47,89,122,.25) !important;
}

.checklist-items-container {
  border: none !important;
  border-bottom: 1px solid rgba(215, 208, 208, 0.26) !important;
}

.checklist-item-card .card-body .el-form-item--mini.el-form-item,
.checklist-item-card .card-body .el-form-item--small.el-form-item {
  margin-bottom: 0 !important;
}


.checklist_dropdown {
  height: 20px !important;
}

.no-border-card .card-footer {
  border-top: 0;
}

.input_height input {
  height: 28px !important;
}

.input_height .el-input__prefix {
  margin-top: -6px;
}
.input_height .el-input__suffix {
  margin-top: 2px;
}


.el-menu {
  background-color: transparent;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}

.el-submenu__title:hover {
  background-color: transparent;
}

.el-submenu__title:hover {
  background-color: transparent !important;
}

.el-submenu__title {
  height: 18px !important;
  line-height: 0px !important;
}

.customButtonStyle {
  padding-top: 0rem !important;
  padding-bottom: 0px !important;
}
.btnDraggable {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  cursor: grabbing;
  margin-left: 0px;
}

.customInputStyle {
  /*padding-bottom: 0px !important;*/
}
.customInputStyle .cell .input_height {
  width: 100%;
}

.customInputStyle .cell .input_height .el-input__inner{
  width: inherit;
}

.checklist-table thead th {
  font: normal 600 10px/9px Open Sans;
  letter-spacing: 0.95px;
  color: #8898AA;
  text-transform: uppercase;
  opacity: 1;
}

.el-tabs__content{
  overflow: visible !important;
}

.checklist-table{
  overflow: visible !important;
}
.el-table__body-wrapper{
  overflow: visible !important;
}
.cell{
  overflow: visible !important;
}

.input-group-append .btn {
  position: initial !important;
}

.checklist-start-date .dp-hide-input-div {
  left: -93px;
}

.dp-hide-input-div-cd .dp-hide-input-div {
  left: -71px;
}

div.patient-checklist-table > .el-table__body-wrapper > .el-table__body > tbody > .el-table__row:hover {
  background-color: #dbdde1 !important;
}

/* Explicitly showing scroll bar even if gets hide */
div.patient-checklist-table > div.el-table__body-wrapper{
  overflow: auto !important;
}
.el-table-upper-scroll{
  overflow-x: auto ;
  overflow-y: hidden;
  height: 20px;
  width: 100%;
  .__scroll{
      height: 20px;
  }
}
</style>
