<template>
  <div class="ml-4 mr-4 mt-4">
    <!-- Notification Modal -->
    <div class="modals">
      <modal @resetModal="resetForm" :show.sync="modals.detailsModal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold" id="SendMessageModal">Add Detail</h4>
        </template>
        <!-- Notification Modal Header Finished -->

        <!-- Notification Modal Body -->
        <template slot="default">
          <div class="mt--4 ml--1 mb-4">
            <p class="modal-label mb-2">Detail</p>
            <template v-if="request.selectedChecklistItems.length>0">
              <editor
                v-model="request.templateDetailValue"
                :api-key=editorApiKey
                :init=editorOption
              />
            </template>
          </div>
        </template>
        <!-- Notification Modal Body Finished -->

        <!-- Notification Modal Footer -->
        <template slot="footer">
          <div class="mt--5">
            <base-button type="secondary" @click="cancelSaveChecklistTemplateDetail">Cancel</base-button>
            <base-button class="btn-primary-action pl-5 pr-5 br-4" type="success" @click="saveChecklistItemTemplateDetail">
              Save
            </base-button>
          </div>
        </template>
        <!-- Notification Modal Footer Finished -->
      </modal>
      <modal :show.sync="modals.checklistItemsModal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold" id="checklistItemsModal">Add Checklist Items</h4>
        </template>
        <!-- Notification Modal Header Finished -->

        <!-- Notification Modal Body -->
        <template slot="default">
          <div class="mt--4">
            <base-input label="Checklist Item">
              <el-select
                class="w-100"
                placeholder="Select Checklist Item"
                v-model="request.selectedModalItem"
                @change="onSelectedChecklistItem"
                filterable>
                <el-option
                  v-for="option in response.list"
                  class="select-danger"
                  :value="option"
                  :label="option.description"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <template v-if="request.selectingChecklistItem.length > 0">
              <div class="select-items-label mt--3 mb-2">Selected Items</div>
              <div class="selected-items scrollList">
                <div class="d-flex justify-content-between pt-3 pb-3 pl-4 pr-4 selected-item"
                     v-for="selectingItem in request.selectingChecklistItem"
                     :key="selectingItem.id">
                  <div class="added-checklist-item">
                    {{ selectingItem.description }}
                  </div>
                  <div @click="onRemoveSelectedItem(selectingItem)">
                    <img src="/img/icons/buttons/remove.svg" alt="*">
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
        <!-- Notification Modal Body Finished -->

        <!-- Notification Modal Footer -->
        <template slot="footer">
          <div class="">
            <base-button type="secondary" @click="modals.checklistItemsModal=false">Cancel</base-button>
            <base-button class="btn-primary-action pl-5 pr-5 br-4" type="success" @click="onAddChecklistItemsModal">
              Add
            </base-button>
          </div>
        </template>
        <!-- Notification Modal Footer Finished -->
      </modal>
    </div>
    <div class="forms">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Template name"
                  label="Template Name*" v-model="request.templateForm.name"
                  placeholder="Template Name">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-12">
                <base-input label="Template Stage">
                  <el-select
                    class="w-100"
                    placeholder="Template Stage Select"
                    label="Template Stage"
                    v-model="request.templateForm.stage"
                    filterable>
                    <el-option
                      v-for="option in dropdowns.stages"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <base-input label="Status">
                  <el-select
                    class="w-100"
                    label="Status"
                    placeholder="Status Select"
                    v-model="request.templateForm.status"
                    filterable>
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </card>
          <checklist-item-card
            ref="checklistItemCard"
            screen="add-checklist-template"
            :show-patient-check-icon="true"
            :is-template="showIsTemplate"
            :items-list="request.selectedChecklistItems"
            :template-items="otherTemplateChecklistItems"
            :loader="loaders.items"
            :show-headers="true"
            :actions="buttons.actions"
            :table-columns="tables.checklistItemsTable"
            @addToUpdateList="handleUpdateList"
            @handleDelete="handleDelete"
            @addChecklistItemRow="addChecklistItemRow"
            @openChecklistModal="openChecklistModal"
          >
            <template v-slot:header>
              <h3 class="text-uppercase mt-2 mb-4 font-weight-700">Checklist items</h3>
            </template>
            <template v-slot:addChecklistItem>
              <base-button
                type="secondary"
                class="pt-1 pb-1 pl-1 pr-3 font-weight-normal add-checklist-buttons"
                @click="toggleChecklistItemModal"
              >
                <img src="/img/icons/buttons/plus.svg" alt="+"> Add Checklist Item
              </base-button>
            </template>
          </checklist-item-card>
        </form>
        <base-button
          @click="onSubmit('checklistForm')"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ buttonText }}
        </base-button>
      </validation-observer>
    </div>
  </div>

</template>

<script>
import ChecklistItemCard from "@/views/Components/Checklist/ChecklistItemCard";
import _ from "lodash";
import PerfectScrollbar from "perfect-scrollbar";
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import {store} from "@/vuex/store";
export default {
  name: "ChecklistTemplateForm",
  props: ['checklistItems', 'checklistItemToEdit', 'loaders', 'buttonText', 'otherTemplateItems'],
  components: {
    ChecklistItemCard,
  },
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      modals: {
        detailsModal: false,
        checklistItemsModal: false,
      },
      request: {
        templateForm: {
          name: '',
          stage: +this.$route.params.id,
          status: 1,
        },
        selectedChecklistItems: [],
        selectingChecklistItem: [],
        toUpdateChecklistItems: [],
        toRemoveChecklistItems: [],
        templateDetailValue: '',
        detailBeforeCancel: '',
        selectedModalItem: '',
      },
      response: {
        list: [],
        index: 0
      },
      dropdowns: {
        stages: [
          {
          label: 'Pre-Op',
          value: 1
        },
          {
            label: 'Post-Op',
            value: 2,
          },
          {
            label: 'Weight-Management',
            value: 3,
          }
        ],
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      buttons: {
        actions: [
          {
            src: '/img/icons/buttons/remove.svg',
            action: 'delete'
          }
        ],
      },
      tables: {
        checklistItemsTable: [
          {
            prop: "description",
            label: "Checklist Item",
            minWidth: 80,
          },
          {
            prop: 'days_to_complete',
            label: 'Days to complete',
            minWidth: 80,
          },
        ]
      },
      otherTemplateChecklistItems: [],
      showIsTemplate: false,
    }
  },
  computed: {
    getContentValue() {
      let value = ""
      if (this.request.selectedChecklistItems.length > 0) {
        value = _.cloneDeep(this.request.selectedChecklistItems[this.response.index]).template_detail;
      }
      return value;
    },
  },
  watch: {
    otherTemplateItems(newVal) {
      this.otherTemplateChecklistItems = newVal
      this.showIsTemplate = true
    },

    checklistItems(newVal) {
      this.response.list = [...new Map(newVal.map(item => [item['description'], item])).values()]
    },

    checklistItemToEdit(newVal) {
      this.request.templateForm = newVal;
      this.request.templateForm.stage = newVal.temp_stage;
      //Assigning Items to selectedChecklistItems
      if (newVal.hasOwnProperty('new_todo_settings')) {
        this.request.selectedChecklistItems = newVal.new_todo_settings;
      }
    }
  },
  methods: {
    toggleChecklistItemModal() {
      this.request.selectingChecklistItem.splice(0);
      this.modals.checklistItemsModal = !this.modals.checklistItemsModal;
    },

    handleKeyPressForEditor(value) {
      this.request.templateDetailValue = value;
    },

    handleUpdateList(element) {
      if (element.hasOwnProperty("id")) {
        this.request.toUpdateChecklistItems.push(element);
      }
    },

    openChecklistModal(event) {
      this.request.selectedChecklistItems[event.index] = event.row;
      this.response.index = event.index;
      //update here
      this.request.templateDetailValue = '';
      this.$refs.formValidator.reset();
      this.request.templateDetailValue = event.row.template_detail;
      this.request.detailBeforeCancel = event.row.template_detail;
      this.modals.detailsModal = true;
    },

    onAddChecklistItemsModal() {
      for (let selecting of this.request.selectingChecklistItem) {
        if (!_.find(this.request.selectedChecklistItems, selecting)) {
          this.request.selectedChecklistItems.push({
            'id':'0',
            'description':selecting.description,
            'days_to_complete':selecting.days_to_complete,
            'template_detail':selecting.template_detail,
            'patient_can_mark':selecting.patient_can_mark ?? true,
          });
        }
      }
      this.toggleChecklistItemModal();
    },

    resetForm() {
      this.request.templateDetailValue = ''
      this.$refs.formValidator.reset()
    },

    onSelectedChecklistItem(value) {
      this.request.selectingChecklistItem.push(value);
      this.response.list.splice(_.findKey(this.response.list, function (e) {
        return e.id === value.id;
      }), 1);
      this.request.selectedModalItem = '';
    },

    cancelSaveChecklistTemplateDetail() {
      this.request.templateDetailValue = this.request.detailBeforeCancel;
      //update here
      this.request.selectedChecklistItems[this.response.index].template_detail = this.request.detailBeforeCancel;
      this.request.templateDetailValue = '';
      this.modals.detailsModal = false;
    },

    saveChecklistItemTemplateDetail() {
      //update here
      this.request.selectedChecklistItems[this.response.index].template_detail = this.request.templateDetailValue;
      this.request.templateDetailValue = '';
      this.modals.detailsModal = false;
    },

    async onSubmit(formName) {
      // debugger
      const isTemplateFormValid = await this.$refs['formValidator'].validate();

      let counter = 0;
      let validForm = true;

      while (counter < this.$refs.checklistItemCard.$refs[formName].length) {
        this.$refs.checklistItemCard.$refs[formName][counter].validate((valid) => {
          if (!valid) {
            validForm = false;
            return false;
          }
        });
        counter += 1;
      }
      if (isTemplateFormValid && validForm) {
        if (this.$route.name === 'StoreChecklistTemplate') {
          this.$emit('createChecklistTemplate', this.request);
        } else if (this.$route.name === 'EditChecklistTemplate') {
          this.$emit('editChecklistTemplate', this.request);
        } else {
          this.$emit('copyChecklistTemplate', this.request);
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    addChecklistItemRow() {
      let checklistItem = {
        id:'0',
        description: '',
        days_to_complete: null,
        template_detail: '',
        patient_can_mark: true,
      };
      this.response.list.push(checklistItem);
      this.request.selectedChecklistItems.push(checklistItem)
    },

    onRemoveSelectedItem(selectedItem) {
      this.request.selectedChecklistItems.splice(_.findKey(this.request.selectedChecklistItems, function (e) {
        return e.id === selectedItem.id;
      }), 1);

      this.request.selectingChecklistItem.splice(_.findKey(this.request.selectingChecklistItem, function (e) {
        return e.id === selectedItem.id;
      }), 1);

      this.response.list.push(selectedItem);
    },

    handleDelete(event) {
      if (event.element.hasOwnProperty("id") && event.element.id != 0) {
        this.request.selectedChecklistItems.splice(_.findKey(this.request.selectedChecklistItems, function (e) {
          return e.id === event.element.id;
        }), 1);
        this.request.selectingChecklistItem.splice(_.findKey(this.request.selectingChecklistItem, function (e) {
          return e.id === event.element.id;
        }), 1);
        //this.response.list.push(event.element);
        this.request.toRemoveChecklistItems.push(event.element.id);
      }else{
        this.request.selectedChecklistItems.splice(event.index, 1);
      }
    },

  },
  mounted: function (){
      //this.scrollInitialized();
  }
}
</script>

<style scoped>
.scrollList {
  overflow: scroll;
  height: 200px;
}

.vueditor{
  min-height: 230px !important;
}
</style>
