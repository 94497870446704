<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4 ">
      <h1 class="">Edit Template</h1>
    </div>
    <checklist-template-form
      button-text="Update"
      :loaders="loaders"
      :checklist-items="response.checklistItems"
      :checklist-item-to-edit="response.checklistItemToEdit"
      :other-template-items="response.TemplateItems"
      @editChecklistTemplate="editChecklistTemplate"
    />
  </div>
</template>

<script>
import ChecklistTemplateForm from "@/views/Components/Checklist/ChecklistTemplateForm";
import BackButton from "@/components/Router/BackButton";
import swal from "sweetalert2";

export default {
  name: "EditChecklistTemplate",
  components: {
    ChecklistTemplateForm,
    BackButton
  },
  data() {
    return {
      loaders: {
        items: false,
      },
      response: {
        checklistItems: [],
        checklistItemToEdit: [],
        TemplateItems: [],
      },
      request: {
        editId: this.$route.params.rowId,
      },
    }
  },
  methods: {
    getChecklistItems() {
      let vm = this;
      vm.loaders.items = true;
      const tempStage = this.$route.params.id ?? -1;
      axios.get(this.$store.getters.getBaseUrl + `/api/checklist/items?tempStage=${tempStage}&templateId=${vm.request.editId}`).then(response => {
        vm.response.checklistItems = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Items',
          message: message
        });
      }).finally(() => {
        vm.loaders.items = false;
      });
    },
    getChecklistItem() {
      let vm = this;
      vm.loaders.items = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/checklist/templates/show?id=${this.request.editId}`).then(response => {
        vm.response.checklistItemToEdit = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Items',
          message: message
        });
      }).finally(() => {
        vm.loaders.items = false;
      });
    },

    /**
     * Get checklist items from other templates
     */
    getOtherTemplateItems() {
      let vm  = this
      vm.loaders.items = true
      const payload = {
        templateId: vm.request.editId,
      }
      vm.$store.dispatch('_getOtherTemplateItems', payload)
        .then(response => {
          vm.response.TemplateItems = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Template Items',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.items = false
        });
    },

    editChecklistTemplate(event) {
      let vm = this;
      swal.fire({
        icon: 'warning',
        text: 'ALERT: Changing the checklist templates can cause pipeline automations and reports to fail. Please contact your Baritastic representative if you are unsure how changes here will affect your automations and reporting.',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          header: 'edit-template-header',
        },
        width: '700px',
        confirmButtonColor: '#832C44',
        cancelButtonColor: '#8898AA',
      }).then((result) => {
        if (result.isConfirmed) {
          let editChecklistTemplateRequest = {
            id: this.request.editId,
            name: event.templateForm.name,
            temp_stage: event.templateForm.stage,
            status: event.templateForm.status,
            checklistItems: event.selectedChecklistItems,
            checklistItemsToRemove: event.toRemoveChecklistItems
          };
          editChecklistTemplateRequest.checklistItems.forEach((item, index) => {
            item.order_key = index + 1;
          });
          axios.post(this.$store.getters.getBaseUrl + `/api/checklist/templates/update`, editChecklistTemplateRequest).then(response => {
            vm.$notify.success({
              title: 'Success',
              message: 'Checklist Template Updated'
            });
            vm.getChecklistItem();
            vm.getChecklistItems();
          }).catch(error => {
            let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
            vm.$notify.error({
              title: 'Checklist Template',
              message: message
            });
          });
        }
      })
    }
  },
  mounted() {
    this.getChecklistItems();
    this.getChecklistItem();
    this.getOtherTemplateItems()
  }
}
</script>

<style>
.edit-template-header > .swal2-icon {
  width: 4em !important;
  height: 4em !important;
  color: #f5365c;
  border-color: #f5365c;
}
</style>
