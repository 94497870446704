<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Add New Template</h1>
    </div>
    <checklist-template-form
      v-loading="loaders.template"
      button-text="Save"
      :loaders="loaders"
      :checklist-items="response.checklistItems"
      :other-template-items="response.TemplateItems"
      @createChecklistTemplate="createChecklistTemplate"
    />
  </div>
</template>

<script>
import ChecklistTemplateForm from "@/views/Components/Checklist/ChecklistTemplateForm";
import BackButton from "@/components/Router/BackButton";

export default {
  name: "AddChecklistTemplate",
  components: {
    ChecklistTemplateForm,
    BackButton
  },
  data() {
    return {
      loaders: {
        items: false,
        template: false
      },
      response: {
        checklistItems: [],
        TemplateItems: [],
      },
    }
  },
  methods: {
    getChecklistItems() {
      let vm = this;
      vm.loaders.items = true;
      const tempStage = this.$route.params.id ?? -1;
      axios.get(this.$store.getters.getBaseUrl + `/api/checklist/items?tempStage=${tempStage}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.checklistItems = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Items',
          message: message
        });
      }).finally(() => {
        vm.loaders.items = false;
      });
    },

    createChecklistTemplate(event) {
      let vm = this;
      vm.loaders.template = true;
      let createChecklistTemplateRequest = {
        name: event.templateForm.name,
        temp_stage: event.templateForm.stage,
        status: event.templateForm.status,
        checklistItems: event.selectedChecklistItems,
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/checklist/templates`, createChecklistTemplateRequest).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Success',
          message: 'Checklist Template Created'
        });
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Template',
          message: message
        });
      }).finally(() => {
        vm.loaders.template = false;
      });
    },

    /**
     * Get checklist items from templates
     */
    getOtherTemplateItems() {
      let vm  = this
      vm.loaders.items = true
      const payload = {
        templateId: -1,
      }
      vm.$store.dispatch('_getOtherTemplateItems', payload)
      .then(response => {
        vm.response.TemplateItems = response.data.data
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Template Items',
          message: message
        });
      })
      .finally(() => {
        vm.loaders.items = false
      });
    }
  },

  mounted() {
    this.getChecklistItems();
    this.getOtherTemplateItems()
  }
}
</script>

<style scoped>
.modal-label {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  opacity: 1;
}

.selected-items {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 2px #E9ECEF0D;
  border: 1px solid #e6e6ec;
  border-radius: 2px;
  opacity: 1;
}

.selected-item:not(:last-child) {
  border-bottom: 1px solid #e6e6ec;
}

.added-checklist-item {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #232323;
  opacity: 1;
}

.select-items-label {
  text-align: left;
  font: bold bold normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  opacity: 1;
}
</style>

<style>
.el-select-dropdown.el-popper .el-select-dropdown__item.selected,
.el-select-dropdown.el-popper.is-multiple .el-select-dropdown__item.selected {
  color: black;
  font-weight: 600;
}
</style>
