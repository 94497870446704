<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Copy Template</h1>
    </div>
    <checklist-template-form
      v-loading="loaders.template"
      button-text="Save"
      :loaders="loaders"
      :checklist-items="response.checklistItems"
      :checklist-item-to-edit="response.checklistItemToEdit"
      :other-template-items="response.TemplateItems"
      @copyChecklistTemplate="copyChecklistTemplate"
    />
  </div>
</template>

<script>
import ChecklistTemplateForm from "@/views/Components/Checklist/ChecklistTemplateForm";
import BackButton from "@/components/Router/BackButton";

export default {
  name: "CopyChecklistTemplate",
  components: {
    ChecklistTemplateForm,
    BackButton
  },
  data() {
    return {
      loaders: {
        items: false,
        template: false
      },
      response: {
        checklistItems: [],
        checklistItemToEdit: [],
        TemplateItems: [],
      },
      request: {
        editId: this.$route.params.rowId,
      },
    }
  },
  methods: {
    getChecklistItems() {
      let vm = this;
      vm.loaders.items = true;
      const tempStage = this.$route.params.id ?? -1;
      axios.get(this.$store.getters.getBaseUrl + `/api/checklist/items?tempStage=${tempStage}&templateId=${vm.request.editId}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.checklistItems = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Items',
          message: message
        });
      }).finally(() => {
        vm.loaders.items = false;
      });
    },

    getChecklistItem() {
      let vm = this;
      vm.loaders.items = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/checklist/templates/show?id=${this.request.editId}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.checklistItemToEdit = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Items',
          message: message
        });
      }).finally(() => {
        vm.loaders.items = false;
      });
    },

    /**
     * Get checklist items from other templates
     */
    getOtherTemplateItems() {
      let vm  = this
      vm.loaders.items = true
      const payload = {
        templateId: vm.request.editId,
      }
      vm.$store.dispatch('_getOtherTemplateItems', payload)
        .then(response => {
          vm.response.TemplateItems = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Template Items',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.items = false
        });
    },

    copyChecklistTemplate(event) {
      let vm = this;
      vm.loaders.template = true;
      let createChecklistTemplateRequest = {
        name: event.templateForm.name,
        temp_stage: event.templateForm.stage,
        status: event.templateForm.status,
        checklistItems: event.selectedChecklistItems,
      };
      createChecklistTemplateRequest.checklistItems.forEach((item,index)=>{
        item.order_key = index + 1;
      });
      axios.post(this.$store.getters.getBaseUrl + `/api/checklist/templates`, createChecklistTemplateRequest).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Success',
          message: 'Checklist Template Created'
        });
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Template',
          message: message
        });
      }).finally(() => {
        vm.loaders.template = false;
      });
    },
  },
  mounted() {
    this.getChecklistItems();
    this.getChecklistItem();
    this.getOtherTemplateItems()
  }
}
</script>

<style scoped>

</style>
