<template>
  <modal @resetModal="cancelChecklistTemplateDetail" :show.sync="modelSync" v-loading="loaders.ChecklistItemDetail">
    <template slot="header">
      <h4 class="modal-title font-weight-bold" id="SendMessageModal">Add Detail</h4>
    </template>
    <template slot="default">
      <div class="mt--4 ml--1 mb-4">
        <p class="note-label mb-4">Note: When your patient clicks on this item in their checklists, they will see the notes below.</p>
        <editor
          v-model="text"
          :api-key=editorApiKey
          :init=editorOption
        />
      </div>
    </template>
    <template slot="footer">
      <div class="mt--5">
        <base-button type="secondary" class="btn-gray" @click="cancelChecklistTemplateDetail">Cancel</base-button>
        <base-button class="btn-primary-action pl-5 pr-5 br-4" type="success" @click="saveChecklistItemTemplateDetail">
          Save
        </base-button>
      </div>
    </template>
  </modal>
</template>

<script>
import {store} from "@/vuex/store";

export default {
  name: "ChecklistItemDetailModal",
  props: ['itemId', 'detailText', 'modelSync'],
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      text: '',
      loaders: {
        ChecklistItemDetail: false
      }
    }
  },
  watch: {
    detailText: function (newVal) {
      this.text = newVal ? newVal : ''
    }
  },
  methods: {
    /**
     * Hide the Checklist Item Detail Modal
     */
    cancelChecklistTemplateDetail() {
      this.$emit('hideChecklistItemDetailModal')
    },

    /**
     * Save the Checklist Item Detail
     */
    saveChecklistItemTemplateDetail() {
      let vm = this
      vm.loaders.ChecklistItemDetail = true
      const payload = {
        id: vm.itemId,
        detail: vm.text
      }
      vm.$store.dispatch('_updateChecklistItemDetail', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Checklist Item Detail updated successfully.'
          })
          this.$emit('saveChecklistItemTemplateDetail', payload)
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Checklist Item Detail',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.ChecklistItemDetail = false
        })
    }
  }
}
</script>

<style scoped>
.note-label {
  font-size: 14px;
  color: black;
  opacity: 0.8;
  font-weight: 500;
}
</style>
