import moment from "moment";
export default {
  dobPickerOptions: {
    disabledDate (date) {
      return date > new Date() || date < new Date(1939, 12)
    }
  },
  dtPickerOptions: {
    disabledDate (date) {
      return date > new Date() || date < new Date(1939, 12)
    }
  },
  fdPickerOptions: {
    disabledDate (date) {
      return date < new Date();
    }
  },
  surgeryDateOptions: {
    disabledDate (date) {
      return date < moment().subtract(1, 'days')
    }
  },
  dateGreaterThanToday: {
    disabledDate (date) {
      return date >= moment()
    }
  },
  dtGTOETT: {
    disabledDate (date) {
      return date < moment().subtract(1, 'days');
    }
  },
  dateLessThanToday: {
    disabledDate (date) {
      return date < moment().subtract(1, 'days')
    }
  },
}
